const NotificationBanner = ({ notifications, onDismiss }) => {
    return (
      <div className="notification-banner">
        {notifications.length === 0 ? (
          <p>No notifications at this time.</p>
        ) : (
          notifications.map((notification, index) => (
            <div key={index} className={`notification-item ${notification.type.toLowerCase()}`}>
              <p>{notification.message}</p>
              <button onClick={() => onDismiss(index)}>Dismiss</button>
            </div>
          ))
        )}
      </div>
    );
  };
  
  export default NotificationBanner;
  