import { useState } from 'react';

const CertificationForm = ({ onSubmit, onCancel, initialData }) => {
  const [certType, setCertType] = useState(initialData?.certType || '');
  const [issueDate, setIssueDate] = useState(initialData?.issueDate || '');
  const [expirationDate, setExpirationDate] = useState(initialData?.expirationDate || '');

  const handleSubmit = () => {
    if (certType && issueDate && expirationDate) {
      onSubmit({ certType, issueDate, expirationDate });
    }
  };

  return (
    <div className="certification-form">
      <h2>{initialData ? 'Edit Certification' : 'Add New Certification'}</h2>
      <label>Certification Type:</label>
      <input type="text" value={certType} onChange={(e) => setCertType(e.target.value)} />

      <label>Issue Date:</label>
      <input type="date" value={issueDate} onChange={(e) => setIssueDate(e.target.value)} />

      <label>Expiration Date:</label>
      <input type="date" value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)} />

      <button onClick={handleSubmit}>Submit</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};

export default CertificationForm;
