// Import React hooks and required components for routing and UI functionalities
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import CertificationForm from './CertificationForm';
import VendorCertificationDetails from './VendorCertificationDetails';
import NotificationBanner from './NotificationBanner';
import AddVendorForm from './AddVendorForm'; // Import AddVendorForm component
import mockVendors from '../data/mockVendors'; // Import mock data
import '../styles/vendor.css'; // Import styles for vendor management layout and visuals

// Main component for managing vendor compliance data and actions
const VendorManagement = () => {
  // State variables for managing vendor data, selections, and UI interactions
  const [vendors, setVendors] = useState([]); // Stores list of all vendors
  const [selectedVendor, setSelectedVendor] = useState(null); // Stores currently selected vendor's data
  const [notifications, setNotifications] = useState([]); // Stores notifications for expiring or pending certifications
  const [isEditingCertification, setIsEditingCertification] = useState(false); // Tracks if a certification is in edit mode
  const [certificationToEdit, setCertificationToEdit] = useState(null); // Stores the certification being edited
  const [showUploadModal, setShowUploadModal] = useState(false); // Controls visibility of document upload modal
  const [documentUpload, setDocumentUpload] = useState(''); // Stores name of document to be uploaded


  // New state for adding vendor functionality
  const [showAddVendorForm, setShowAddVendorForm] = useState(false);
  const [newVendor, setNewVendor] = useState({
    name: '',
    complianceStatus: '',
    lastContact: '',
  });

  const { vendorId } = useParams(); // Extracts vendor ID from the URL parameters

  // Effect to load vendors and set notifications on component mount or when vendorId changes
  useEffect(() => {
    setVendors(mockVendors); // Initialize vendors from mock data

    // Set selected vendor based on vendorId URL param if provided
    if (vendorId) {
      const selected = mockVendors.find(vendor => vendor.id === parseInt(vendorId, 10));
      if (selected) setSelectedVendor(selected);
    }

    // Generate notifications for certifications that are pending renewal or expired
    const newNotifications = mockVendors.flatMap(vendor =>
      vendor.certifications
        .filter(cert => cert.status === 'Pending Renewal' || cert.status === 'Expired')
        .map(cert => ({
          type: cert.status,
          message: `${vendor.name} - ${cert.type} is ${cert.status.toLowerCase()} (Expires: ${cert.expirationDate})`
        }))
    );
    setNotifications(newNotifications);
  }, [vendorId]);

  // Handle input for document upload
  const handleDocumentUpload = e => {
    setDocumentUpload(e.target.value);
  };


  // Function to handle adding a new vendor
  const handleAddVendor = (newVendor) => {
    setVendors((prevVendors) => [...prevVendors, { ...newVendor, id: prevVendors.length + 1 }]);
    setShowAddVendorForm(false); // Hide the form after adding
  };

  // Handle submission of a new document to the selected vendor's records
  const handleUploadSubmit = () => {
    if (selectedVendor && documentUpload) {
      const updatedVendors = vendors.map(vendor => {
        if (vendor.id === selectedVendor.id) {
          return {
            ...vendor,
            documents: [...vendor.documents, documentUpload], // Add new document
            missingDocuments: vendor.missingDocuments.filter(doc => doc !== documentUpload) // Remove from missing
          };
        }
        return vendor;
      });
      setVendors(updatedVendors);
      setShowUploadModal(false); // Hide upload modal after submission
      setDocumentUpload(''); // Reset document upload field
    }
  };

  // Trigger reminder notification for a vendor's missing documents
  const handleSendReminder = vendor => {
    alert(`Reminder sent to ${vendor.name} for missing documents.`);
  };

  // Dismiss a notification by removing it from the notifications array
  const handleDismissNotification = index => {
    setNotifications(prev => prev.filter((_, i) => i !== index));
  };

  // Set up a certification to be edited
  const handleEditCertification = cert => {
    setCertificationToEdit(cert);
    setIsEditingCertification(true);
  };

  // Handle form submission for adding or updating certifications
  const handleFormSubmit = cert => {
    if (selectedVendor) {
      const updatedVendors = vendors.map(vendor => {
        if (vendor.id === selectedVendor.id) {
          const updatedCertifications = certificationToEdit
            ? vendor.certifications.map(c => (c.type === certificationToEdit.type ? cert : c))
            : [...vendor.certifications, cert]; // Add new certification if none is being edited
          return { ...vendor, certifications: updatedCertifications };
        }
        return vendor;
      });
      setVendors(updatedVendors);
      setIsEditingCertification(false); // Exit edit mode
      setCertificationToEdit(null); // Clear edited certification state
    }
  };

  // Handle deletion of a certification from the selected vendor
  const handleDeleteCertification = cert => {
    const updatedVendors = vendors.map(vendor => {
      if (vendor.id === selectedVendor.id) {
        return {
          ...vendor,
          certifications: vendor.certifications.filter(c => c.type !== cert.type) // Remove certification
        };
      }
      return vendor;
    });
    setVendors(updatedVendors);
  };

  return (
    <div className="vendor-management">
      <h1>Vendor Management</h1>
      <Link to="/" className="back-link">Back to Perfume Dashboard</Link>

      {/* Notification Banner - Display notifications at top of the page */}
      <NotificationBanner notifications={notifications} onDismiss={handleDismissNotification} />

      {/* Add Vendor Button */}
      <button className="add-vendor-button"  onClick={() => setShowAddVendorForm(true)}>Add Vendor</button>

      {/* Add Vendor Form */}
      {showAddVendorForm && (
        <AddVendorForm
          onAddVendor={handleAddVendor}
          onCancel={() => setShowAddVendorForm(false)}
        />
      )}
      {/* Main Content Area with Vendor List and Vendor Details Side-by-Side */}
      <div className="main-content-vendor">
        {/* Vendor List - Left Panel */}
        <div className="vendor-list">
          {vendors.length === 0 ? (
            <p>No vendors available.</p>
          ) : (
            vendors.map(vendor => (
              <div key={vendor.id} className="vendor-card" onClick={() => setSelectedVendor(vendor)}>
                <h3>{vendor.name}</h3>
                <p>Compliance Status: {vendor.complianceStatus}</p>
                {vendor.missingDocuments.length > 0 && (
                  <p>Missing Documents: {vendor.missingDocuments.join(', ')}</p>
                )}
                <p>Last Contact: {vendor.lastContact}</p>
                <button onClick={e => { e.stopPropagation(); handleSendReminder(vendor); }}>Send Reminder</button>
              </div>
            ))
          )}
        </div>

        {/* Vendor Details - Right Panel, slides in when a vendor is selected */}
        {selectedVendor && (
          <div className="vendor-details active">
            <button className="close-button" onClick={() => setSelectedVendor(null)}>X</button> {/* Close Button */}
            
            <h2>{selectedVendor.name} - Details</h2>
            <p><strong>Compliance Status:</strong> {selectedVendor.complianceStatus}</p>
            <p><strong>Documents:</strong> {selectedVendor.documents.join(', ')}</p>
            <p><strong>Missing Documents:</strong> {selectedVendor.missingDocuments.length ? selectedVendor.missingDocuments.join(', ') : 'None'}</p>
            <p><strong>Last Contacted:</strong> {selectedVendor.lastContact}</p>

            <VendorCertificationDetails
              vendor={selectedVendor}
              onClose={() => setSelectedVendor(null)}
              onUpdateCertification={handleFormSubmit}
              onDeleteCertification={handleDeleteCertification}
            />

            {isEditingCertification && (
              <CertificationForm
                initialData={certificationToEdit}
                onSubmit={handleFormSubmit}
                onCancel={() => {
                  setIsEditingCertification(false);
                  setCertificationToEdit(null);
                }}
              />
            )}

            <h3>Communication Log</h3>
            <ul>
              {selectedVendor.communicationLog.map((log, index) => (
                <li key={index}>{log.date}: {log.message}</li>
              ))}
            </ul>

            <button onClick={() => setShowUploadModal(true)}>Upload Missing Document</button>
            {showUploadModal && (
              <div className="modal">
                <div className="modal-content">
                  <h2>Upload Document for {selectedVendor.name}</h2>
                  <input
                    type="text"
                    placeholder="Enter document name"
                    value={documentUpload}
                    onChange={handleDocumentUpload}
                  />
                  <button onClick={handleUploadSubmit}>Submit</button>
                  <button onClick={() => setShowUploadModal(false)}>Cancel</button>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default VendorManagement;
