import React, { useEffect, useState } from 'react';
import FormulaDetails from './FormulaDetails';
import { mockPerfumes } from '../data/mockPerfumes'; // Mock data of perfumes
import '../styles/dashboard.css';

const PerfumeDashboard = () => {
  const [perfumes, setPerfumes] = useState([]);
  const [selectedPerfume, setSelectedPerfume] = useState(null);
  const [isAscending, setIsAscending] = useState(true); // Sorting state
  const [searchQuery, setSearchQuery] = useState(''); // Search bar state
  const [filter, setFilter] = useState(''); // Filter state

  useEffect(() => {
    setPerfumes(mockPerfumes); // Load the mock data
  }, []);

  // Sorting by name
  const sortByName = () => {
    const sortedPerfumes = [...perfumes].sort((a, b) =>
      isAscending ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
    );
    setPerfumes(sortedPerfumes);
    setIsAscending(!isAscending);
  };

  // Sorting by category
  const sortByCategory = () => {
    const sortedPerfumes = [...perfumes].sort((a, b) =>
      a.category.localeCompare(b.category)
    );
    setPerfumes(sortedPerfumes);
  };

  // Search and filter functionality
  const filteredPerfumes = perfumes.filter((perfume) =>
    perfume.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    (filter === 'ifraCertified' && perfume.compliance.ifraCertified) ||
    (filter === 'msdsAvailable' && perfume.compliance.msdsAvailable) ||
    (filter === 'allergenFree' && perfume.compliance.allergenFree)
  );

  // Export perfume data to CSV
  const exportDataToCSV = () => {
    const csvRows = [];
    const headers = ['Name', 'Description', 'Category', 'IFRA Certified', 'MSDS Available', 'Allergen Free'];
    csvRows.push(headers.join(','));

    filteredPerfumes.forEach(perfume => {
      const row = [
        perfume.name,
        perfume.description,
        perfume.category,
        perfume.compliance.ifraCertified ? 'Yes' : 'No',
        perfume.compliance.msdsAvailable ? 'Yes' : 'No',
        perfume.compliance.allergenFree ? 'Yes' : 'No'
      ];
      csvRows.push(row.join(','));
    });

    const csvData = csvRows.join('\n');
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'perfume_data.csv');
    document.body.appendChild(link);
    link.click();
  };

  const compliantPerfumes = perfumes.filter(p => p.compliance.ifraCertified);
  const nonCompliantPerfumes = perfumes.filter(p => !p.compliance.ifraCertified);
  const perfumesRequiringAttention = perfumes.filter(p => p.compliance.ifraExpirySoon);

  return (
    <div className={`dashboard-container ${selectedPerfume ? 'with-formula' : ''}`}>
      {/* Compliance Overview */}
      <div className="compliance-overview">
        <h2>Compliance Overview</h2>
        <p>Total Perfumes: {perfumes.length}</p>
        <p>Compliant Perfumes: {compliantPerfumes.length}</p>
        <p>Non-Compliant Perfumes: {nonCompliantPerfumes.length}</p>
        <p>Perfumes Requiring Attention: {perfumesRequiringAttention.length}</p>
      </div>

      {/* Alert Section */}
      <div className="alert-section">
        {perfumesRequiringAttention.length > 0 && (
          <div className="alerts">
            <h3>Upcoming Compliance Expirations</h3>
            <ul>
              {perfumesRequiringAttention.map((perfume) => (
                <li key={perfume.id}>
                  <strong>{perfume.name}</strong>: IFRA certification expires soon.
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* Sorting and Search Controls */}
      <div className="controls">
        <input
          type="text"
          placeholder="Search perfumes..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <button onClick={sortByName}>
          Sort by Name {isAscending ? '▲' : '▼'}
        </button>
        <button onClick={sortByCategory}>Sort by Category</button>

        <select onChange={(e) => setFilter(e.target.value)} value={filter}>
          <option value="">Filter by</option>
          <option value="ifraCertified">IFRA Certified</option>
          <option value="msdsAvailable">MSDS Available</option>
          <option value="allergenFree">Allergen Free</option>
        </select>

        <button onClick={exportDataToCSV}>Export Data</button>
      </div>

      {/* Main Content Area with Vendor List and Vendor Details Side-by-Side */}
      <div className="main-content-perfume">
        {/* Perfume Grid Container */}
        <div className="perfume-card-container">
          {filteredPerfumes.map((perfume) => (
            <div
              key={perfume.id}
              className={`perfume-card ${selectedPerfume === perfume.id ? 'selected' : ''}`}
              onClick={() => setSelectedPerfume(perfume.id)}
            >
              <h3>{perfume.name}</h3>
              <p>{perfume.description}</p>
              <div className="compliance-badges">
                {perfume.compliance.ifraCertified && <span className="badge badge-ifra">IFRA Certified</span>}
                {perfume.compliance.msdsAvailable && <span className="badge badge-msds">MSDS Available</span>}
                {perfume.compliance.allergenFree && <span className="badge badge-allergen">Allergen Free</span>}
              </div>
            </div>
          ))}
        </div>

        {/* Formula Details Section */}
        {selectedPerfume && (
          <div className="formula-details">
            <FormulaDetails perfumeData={perfumes.find(p => p.id === selectedPerfume)} />
          </div>
        )}
      </div>
    </div>
  );
};

export default PerfumeDashboard;
