import React, { useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types'; // Ensures type safety in a non-TypeScript environment
import '../styles/intelligence.css';

/** MOCK DATA SETUP **/

// Mock data for LLM (OpenAI GPT-3) response
const mockLLMData = [
  { region: 'EU', update: 'New allergen restrictions for fragrance products in 2024.' },
  { region: 'US', update: 'The FDA has announced new labeling requirements for skincare products.' },
];

// Mock data for FDA API response
const mockFDAData = [
  { region: 'US', update: 'FDA Update: New safety guidelines for sunscreen products.' },
  { region: 'US', update: 'FDA Update: Increased monitoring for cosmetic preservatives.' },
];

// Mock data for EU regulatory updates
const mockEUData = [
  { region: 'EU', update: 'EU Regulatory Update: Restriction on parabens usage in skincare products.' },
  { region: 'EU', update: 'EU Regulatory Update: Mandatory allergen labeling for cosmetics by 2025.' },
];

/** MOCK API CALLS **/

// Mock function to simulate fetching data from LLM
const fetchRegulatoryUpdatesFromLLM = async (region = '', category = '') => {
  console.log(`Fetching mock LLM data for ${region || 'global'} region and ${category || 'all'} categories.`);
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockLLMData); // Simulate response delay and return mock data
    }, 1000);
  });
};

// Mock function to simulate fetching data from FDA
const fetchRegulatoryUpdatesFromFDA = async () => {
  console.log('Fetching mock FDA data.');
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockFDAData); // Simulate response delay and return mock FDA data
    }, 1000);
  });
};

// Mock function to simulate fetching data from EU regulatory API
const fetchRegulatoryUpdatesFromEU = async () => {
  console.log('Fetching mock EU data.');
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(mockEUData); // Simulate response delay and return mock EU data
    }, 1000);
  });
};

/** COMPONENT **/

const RegulatoryIntelligence = () => {
  const [regulatoryUpdates, setRegulatoryUpdates] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedRegion, setSelectedRegion] = useState('');
  const [category, setCategory] = useState('');
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);

  // Debounce the filters to prevent excessive API calls
  const debouncedRegion = useMemo(() => selectedRegion, [selectedRegion]);
  const debouncedCategory = useMemo(() => category, [category]);

  // Custom retry logic for API calls with exponential backoff
  const retryFetch = async (fn, retries = 3, delay = 1000) => {
    try {
      return await fn();
    } catch (error) {
      if (retries > 0) {
        console.log(`Retrying API call... Attempts left: ${retries}`);
        await new Promise((res) => setTimeout(res, delay));
        return retryFetch(fn, retries - 1, delay * 2); // Exponential backoff
      } else {
        throw new Error('Max retries reached');
      }
    }
  };

  // Fetch updates from all sources with centralized error handling
  const fetchAllUpdates = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const [llmUpdates, fdaUpdates, euUpdates] = await Promise.all([
        retryFetch(() => fetchRegulatoryUpdatesFromLLM(debouncedRegion, debouncedCategory)),
        retryFetch(fetchRegulatoryUpdatesFromFDA),
        retryFetch(fetchRegulatoryUpdatesFromEU),
      ]);

      // Combine and set the regulatory updates
      setRegulatoryUpdates([...llmUpdates, ...fdaUpdates, ...euUpdates]);
    } catch (error) {
      console.error('Failed to load regulatory updates:', error);
      setError('Failed to load regulatory updates. Please try again later.');
    } finally {
      setLoading(false);
    }
  }, [debouncedRegion, debouncedCategory]);

  useEffect(() => {
    fetchAllUpdates();

    // Optional auto-refresh every 5 minutes if enabled
    if (autoRefresh) {
      const intervalId = setInterval(() => {
        setRefreshCount((prev) => prev + 1); // Update refresh count for better state tracking
        fetchAllUpdates();
      }, 300000); // 5 minutes

      return () => clearInterval(intervalId); // Cleanup on unmount
    }
  }, [autoRefresh, fetchAllUpdates]);

  // Handling loading state
  if (loading) {
    return <p>Loading regulatory updates...</p>;
  }

  // Handling error state
  if (error) {
    return (
      <div>
        <p>{error}</p>
        <button onClick={fetchAllUpdates}>Retry</button>
      </div>
    );
  }

  return (
    <div className="regulatory-intelligence">
      <h1>Regulatory Intelligence</h1>

      {/* Region and Category Filters */}
      <div className="filters">
        <label>
          Select Region:
          <select
            value={selectedRegion}
            onChange={(e) => setSelectedRegion(e.target.value)}
          >
            <option value="">Global</option>
            <option value="EU">EU</option>
            <option value="US">US</option>
          </select>
        </label>

        <label>
          Select Category:
          <select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="">All Categories</option>
            <option value="Cosmetics">Cosmetics</option>
            <option value="Skincare">Skincare</option>
            <option value="Fragrance">Fragrance</option>
          </select>
        </label>
      </div>

      {/* Toggle Auto-refresh */}
      <div className="auto-refresh">
        <label>
          <input
            type="checkbox"
            checked={autoRefresh}
            onChange={(e) => setAutoRefresh(e.target.checked)}
          />
          Auto-refresh every 5 minutes
        </label>
      </div>

      {/* Display updates */}
      <ul>
        {regulatoryUpdates.length > 0 ? (
          regulatoryUpdates.map((update, index) => (
            <li key={index}>
              <h3>{update.region}</h3>
              <p>{update.update}</p>
            </li>
          ))
        ) : (
          <p>No regulatory updates available at this time.</p>
        )}
      </ul>

      <div>Auto-refresh count: {refreshCount}</div> {/* To track auto-refresh cycles */}
    </div>
  );
};

RegulatoryIntelligence.propTypes = {
  selectedRegion: PropTypes.string,
  category: PropTypes.string,
  autoRefresh: PropTypes.bool,
};

export default RegulatoryIntelligence;
