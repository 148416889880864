import React, { useState } from "react";
import Tesseract from "tesseract.js"; // For OCR
import axios from "axios"; // For OpenAI API calls
import OpenAI from "openai";
import Fuse from "fuse.js"; // For fuzzy matching
import structuredIfraData from "../data/structured_ifra_data.json";

import "../styles/textphotoprocessor.css";

const openai = new OpenAI({
    apiKey: "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",
    dangerouslyAllowBrowser: true 
  });

const TextPhotoProcessor = () => {
  const [inputText, setInputText] = useState(""); // Manage user text input
  const [imageFile, setImageFile] = useState(null); // Manage uploaded image
  const [imageBase64, setImageBase64] = useState(""); // Store Base64-encoded image
  const [results, setResults] = useState([]); // React state to store results globally


  const [ocrText, setOcrText] = useState(""); // Store OCR output
  const [apiResponse, setApiResponse] = useState(null); // Store API response
  const [fuzzyMatch, setFuzzyMatch] = useState(null); // Store fuzzy match result
  const [loading, setLoading] = useState(false); // Manage loading state

  const handleTextInputChange = (e) => {
    console.log("Text input updated:", e.target.value);
    setInputText(e.target.value);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      console.log("Image uploaded:", file.name);
      setImageFile(file); // Save the file to display its name later
  
      // Read the image as a data URL
      const reader = new FileReader();
      reader.onloadend = () => {
        const image = new Image();
        image.src = reader.result;
  
        image.onload = () => {
          // Create a canvas element to compress the image
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");
  
          // Set canvas dimensions (e.g., 800px max width)
          const MAX_WIDTH = 800;
          const scaleSize = MAX_WIDTH / image.width;
          canvas.width = MAX_WIDTH;
          canvas.height = image.height * scaleSize;
  
          // Draw the image onto the canvas
          ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  
          // Compress the image and get the Base64 string
          const compressedBase64 = canvas.toDataURL("image/jpeg", 0.7); // 70% quality
  
          // Remove the `data:image/jpeg;base64,` prefix
          const base64String = compressedBase64.split(",")[1];
          setImageBase64(base64String); // Save the compressed Base64 string
        };
      };
      reader.readAsDataURL(file);
    } else {
      console.warn("No file selected.");
    }
  };
  
  

  const fuzzySearchIngredient = (data, searchTerm, threshold = 0.10) => {
    console.log("=====================================");
    console.log(`🚀 Starting fuzzy search for CAS number '${searchTerm}' with threshold: ${threshold}`);
    console.log("-------------------------------------");
  
    // Flatten the data into a searchable array, using CAS numbers as the main field
    const fuseData = Object.entries(data).map(([docName, value]) => ({
      docName, // Use the document name as metadata
      cas_number: value.ingredient.cas_number, // Flatten CAS number
    }));
  
    // Configure Fuse.js to search only by CAS numbers
    const fuse = new Fuse(fuseData, {
      keys: ["cas_number"], // Focus search on CAS numbers
      threshold, // Lower threshold means stricter matching
      includeScore: true
    });
    console.log("Fuse.js configuration initialized with keys: ['cas_number']");
  
    // Perform the search
    console.log(`🔍 Performing search for CAS number: '${searchTerm}'`);
    const results = fuse.search(searchTerm);
  
    if (results.length > 0) {
      const topMatch = results[0];
      const score = topMatch.score || "N/A"; // Retrieve score if available
  
      console.log(
        `🎯 Match found for CAS number '${searchTerm}' → Document: '${topMatch.item.docName}', CAS: '${topMatch.item.cas_number}', Score: ${score}`
      );
      console.log("Returning top match item:", JSON.stringify(topMatch.item, null, 2));
      console.log("=====================================");
      return topMatch.item; // Return the top match regardless of score
    }
  
    console.log(`❌ No match found for CAS number '${searchTerm}'.`);
    console.log("=====================================");
    return null;
  };

  // Generate messages for OpenAI API based on inputs
const generateMessages = (inputText, imageBase64) => {
  const prompt = `
    Given the following fragrance formula with ingredient names and concentrations, generate a dictionary where each ingredient name is mapped to its corresponding IAUPC name and CAS number.
    If the ingredients do not actually exist add the validIngredient attribute.

    IMPORTANT: Output should only be valid JSON, no additional strings starting with JSON tag.
    
    JSON structure:
      {
        "Chemical 1 Name": {
          "CAS_NUMBER": "cas number 1",
          "common_chemical_name": "IAUPC chemical name 1",
          "concentration_percentage": 0.xx,
          "validIngredient": true
        },
        "Chemical 2 Name": {
            "CAS_NUMBER": "cas number 2",
            "common_chemical_name": "IAUPC chemical name 2",
            "concentration_percentage": 0.xx,
            "validIngredient": true
        },
        ...
        "Invalid Chemical X Name": {
            "CAS_NUMBER": N/A,
            "common_chemical_name": N/A,
            "concentration_percentage": N/A,
            "validIngredient": false
        },
        "Chemical X Name": {
            "CAS_NUMBER": "cas number X",
            "common_chemical_name": "IAUPC chemical name X",
            "concentration_percentage": 0.xx,
            "validIngredient": true
        }
      }
      `;

  const messages = [{ type: "text", text: prompt }];

  if (inputText) {
    messages.push({ type: "text", text: `Formula text:\n${inputText}` });
  }

  if (imageBase64) {
    messages.push({
      type: "image_url",
      image_url: { url: `data:image/jpeg;base64,${imageBase64}` },
    });
  }

  return messages;
};

// Process OpenAI API response
const processApiResponse = async (messages) => {
  const chatCompletion = await openai.chat.completions.create({
    model: "gpt-4o",
    messages: [{ role: "user", content: messages }],
    temperature: 0.5,
    max_tokens: 1000,
  });

  const generatedText = chatCompletion.choices[0].message.content.trim();
  console.log(generatedText);
  const cleanedText = generatedText.replace(/^```json|```$/g, "").trim();
  return JSON.parse(cleanedText);
};

// Print random molecules from a dataset
const printRandomMolecules = (data, count = 20) => {
  const allMolecules = Object.keys(data).sort(() => 0.5 - Math.random()).slice(0, count);
  console.log(`🎲 Random ${count} molecules:`);
  allMolecules.forEach((molecule, index) => {
    console.log(`${index + 1}. ${molecule} - ${JSON.stringify(data[molecule])}`);
  });
};

const performFuzzySearchAndCheck = (parsedResponse, structuredIfraData) => {
  const results = [];

  Object.entries(parsedResponse).forEach(([ingredientName, ingredient]) => {
    const casNumber = ingredient.CAS_NUMBER;
    const concentrationPercentage = ingredient.concentration_percentage ?? null; // Explicitly allow null

    // Log a warning if concentration_percentage is null
    if (concentrationPercentage === null) {
      console.warn(`⚠️ Concentration percentage is null for ingredient: '${ingredientName}'`);
    }

    // If the ingredient is marked as invalid, add it directly to results
    if (ingredient.validIngredient === false) {
      console.log(`❌ Ingredient '${ingredientName}' is marked as invalid.`);
      results.push({
        ingredient_name: ingredientName,
        cas_number: casNumber || "N/A",
        formula_percentage: concentrationPercentage !== null ? concentrationPercentage * 100 : "N/A",
        category_4_threshold: "N/A",
        above_threshold: false,
        validIngredient: false,
      });
      return;
    }

    // If CAS number is missing, consider it invalid
    if (!casNumber) {
      console.warn(`⚠️ No CAS number provided for ingredient: ${JSON.stringify(ingredient)}`);
      results.push({
        ingredient_name: ingredientName,
        cas_number: "N/A",
        formula_percentage: concentrationPercentage !== null ? concentrationPercentage * 100 : "N/A",
        category_4_threshold: "N/A",
        above_threshold: false,
        validIngredient: false,
      });
      return;
    }

    // Perform fuzzy search for the CAS number
    console.log(`🔍 Searching for CAS number: '${casNumber}'`);
    const searchResult = fuzzySearchIngredient(structuredIfraData, casNumber);

    if (searchResult) {
      console.log(
        `✅ Fuzzy match for CAS number '${casNumber}': Found in document '${searchResult.docName}' with matched CAS '${searchResult.cas_number}'`
      );

      const category4Threshold =
        structuredIfraData[searchResult.docName]?.usage_restrictions?.categories?.category_4 || 0;

      const formulaPercentage = concentrationPercentage !== null ? concentrationPercentage * 100 : "N/A";
      const aboveThreshold = formulaPercentage !== "N/A" && formulaPercentage > category4Threshold;

      results.push({
        document_name: searchResult.docName,
        ingredient_name: structuredIfraData[searchResult.docName]?.ingredient?.name || ingredientName,
        cas_number: casNumber,
        formula_percentage: formulaPercentage,
        category_4_threshold: category4Threshold,
        above_threshold: aboveThreshold,
        validIngredient: true,
      });
    } else {
      console.log(`❌ No match found for CAS number '${casNumber}'.`);
      results.push({
        ingredient_name: ingredientName,
        cas_number: casNumber,
        formula_percentage: concentrationPercentage !== null ? concentrationPercentage * 100 : "N/A",
        category_4_threshold: "N/A",
        above_threshold: false,
        validIngredient: false,
      });
    }
  });

  return results;
};


const handleProcessText = async () => {
  if (!inputText && !imageBase64) {
    console.warn("No text or image provided for processing.");
    alert("Please provide text input or upload an image.");
    return;
  }

  console.log("Preparing to process text and/or image.");

  setLoading(true);
  try {
    const messages = generateMessages(inputText, imageBase64);
    console.log("Generated messages for OpenAI API:", messages);

    const parsedResponse = await processApiResponse(messages);
    console.log("Parsed API response:", parsedResponse);

    // printRandomMolecules(structuredIfraData);

    const finalResults = performFuzzySearchAndCheck(parsedResponse, structuredIfraData);
    console.log("Final Results:", finalResults);

    setResults(finalResults); // Store results in state
    setApiResponse(parsedResponse); // Set the API response in state if needed
  } catch (error) {
    console.error("Error calling OpenAI API:", error);
  } finally {
    setLoading(false);
  }
};
  

return (
  <div className="processor-container">
    {/* Logo */}
    <div className="logo">
      <span>Kōdō</span>
    </div>

    <h1>IFRA Compliance Demo</h1>
    <h3 className="note">
      NOTE: We <strong>do not</strong> collect formula data
    </h3>

    {/* Text Input */}
    <textarea
      placeholder={`Enter your formula:\nISO E Super, 5%\nLillyal, 4%\nCitronellol, 3%`}
      value={inputText}
      onChange={handleTextInputChange}
      className="text-input"
    ></textarea>

    <div className="divider">OR</div>

     {/* Modern Image Upload - Camera Icon */}
     <div className="image-upload-container">
      <label htmlFor="image-upload" className="camera-upload-button">
        <span className="camera-icon">📷</span>
      </label>
      <input
        id="image-upload"
        type="file"
        accept="image/*"
        onChange={handleImageUpload}
        className="hidden-input"
      />
      {/* Display image file name */}
      {imageFile && <p className="image-name">{imageFile.name || "Unnamed File"}</p>}

    </div>

    {/* Loading State */}
    {loading && <p className="loading">Processing...</p>}

    {/* Process Button */}
    <button onClick={handleProcessText} disabled={loading}>
      Process Formula
    </button>

    {/* Results Display */}
    {apiResponse && (
      <div className="api-response">
        <h2>IFRA Compliance Results</h2>
        {results.length > 0 ? (
          <div>
            {results.map((result, index) => (
              <div key={index} className="result-item">
                <p>
                  <strong>Ingredient:</strong> {result.ingredient_name} (CAS: {result.cas_number || "N/A"})
                </p>
                <p>
                  <strong>Formula Percentage: </strong> 
                  {typeof result.formula_percentage === "number" && !isNaN(result.formula_percentage)
                    ? `${result.formula_percentage.toFixed(4)}%`
                    : "N/A"}
                </p>
                <p>
                  <strong>Category 4 Threshold:</strong> {result.category_4_threshold ?? "N/A"}%
                </p>
                <p>
                  <strong>Valid IFRA Ingredient:</strong>{" "}
                  {result.validIngredient ? "Yes" : "No"}
                </p>
                {result.validIngredient && (
                  <span
                    className={`badge ${
                      result.above_threshold ? "badge-danger" : "badge-success"
                    }`}
                  >
                    {result.above_threshold
                      ? "Above IFRA Threshold"
                      : "Below IFRA Threshold"}
                  </span>
                )}
              </div>
            ))}
          </div>
        ) : (
          <p>No results to display.</p>
        )}
      </div>
    )}
  </div>
);


};

export default TextPhotoProcessor;
