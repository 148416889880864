import React from 'react';
import '../styles/compliance.css';

const mockProductCompliance = [
    {
      product: 'Floral Bliss',
      region: 'US',
      status: 'Compliant',
      complianceDate: '2023-08-12',
      responsiblePerson: 'John Doe',
      notes: 'All necessary certifications in place.'
    },
    {
      product: 'Floral Bliss',
      region: 'EU',
      status: 'Non-Compliant',
      complianceDate: '2023-07-01',
      responsiblePerson: 'Jane Smith',
      notes: 'Missing allergen declaration.'
    },
    {
      product: 'Woody Vibe',
      region: 'US',
      status: 'Compliant',
      complianceDate: '2023-09-15',
      responsiblePerson: 'Michael Brown',
      notes: 'All documentation is complete.'
    },
    {
      product: 'Woody Vibe',
      region: 'EU',
      status: 'Compliant',
      complianceDate: '2023-08-20',
      responsiblePerson: 'Sarah Green',
      notes: 'Passed all regulatory checks.'
    }
  ];
  
const ProductComplianceTracker = () => {
return (
    <div className="compliance-tracker">
    <h1>Product Compliance Tracker</h1>
    <table className="compliance-table">
        <thead>
        <tr>
            <th>Product</th>
            <th>Region</th>
            <th>Status</th>
            <th>Compliance Date</th>
            <th>Responsible Person</th>
            <th>Notes</th>
        </tr>
        </thead>
        <tbody>
        {mockProductCompliance.map((item, index) => (
            <tr
            key={index}
            className={item.status === 'Non-Compliant' ? 'non-compliant' : ''}
            >
            <td>{item.product}</td>
            <td>{item.region}</td>
            <td>{item.status}</td>
            <td>{item.complianceDate}</td>
            <td>{item.responsiblePerson}</td>
            <td>{item.notes}</td>
            </tr>
        ))}
        </tbody>
    </table>
    </div>
);
};

export default ProductComplianceTracker;
  
