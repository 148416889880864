import CertificationForm from './CertificationForm';
import { useState } from 'react';

const VendorCertificationDetails = ({ vendor, onUpdateCertification, onDeleteCertification }) => {
  const [editingCert, setEditingCert] = useState(null);

  const handleEdit = (cert) => {
    setEditingCert(cert);
  };

  const handleDelete = (cert) => {
    onDeleteCertification(cert);
  };

  const handleFormSubmit = (updatedCert) => {
    onUpdateCertification(updatedCert);
    setEditingCert(null);
  };

  return (
    <div className="vendor-certification-details">
      <h2>Certification Details for {vendor.name}</h2>
      <ul>
        {vendor.certifications.map((cert, index) => (
          <li key={index}>
            {cert.type} - {cert.status} (Expires on: {cert.expirationDate})
            <button onClick={() => handleEdit(cert)}>Edit</button>
            <button onClick={() => handleDelete(cert)}>Delete</button>
          </li>
        ))}
      </ul>

      {editingCert && (
        <CertificationForm
          initialData={editingCert}
          onSubmit={handleFormSubmit}
          onCancel={() => setEditingCert(null)}
        />
      )}
    </div>
  );
};

export default VendorCertificationDetails;
