import React, { useState, useEffect } from 'react';
import '../styles/demosignup.css';
import 'particles.js'

const DemoSignUp = ({ onSignUp, isActive = true }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [problem, setProblem] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false); // New loading state

  // Replace with your Google Apps Script Web App URL
  const GOOGLE_SCRIPT_URL = 'https://script.google.com/macros/s/AKfycbwOQIgEcXNdG7l94tD09TLDA7RU3GmjZzIDExrG0D-dWnAtwK5-2HvIMn-p98Tqcvcr/exec';

  const writeToGoogleSheet = async () => {
    const data = {
      name,
      email,
      problem,
      timestamp: new Date().toISOString(),
    };

    try {
      const response = await fetch(GOOGLE_SCRIPT_URL, {
        method: 'POST',
        mode: 'no-cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response) {
        setSuccess('Your information has been saved successfully!');
        setName('');
        setEmail('');
        setProblem('');
        onSignUp && onSignUp(); // Call the parent component's sign-up callback if provided
      } else {
        throw new Error("Request failed.");
      }
    } catch (error) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !email) {
      setError('Please fill out all required fields.');
      return;
    }
    setError('');
    setSuccess('');
    setLoading(true); // Start loading spinner
    await writeToGoogleSheet();
  };

  useEffect(() => {
    if (isActive && window.particlesJS) {
      window.particlesJS('particles-js', {
        particles: {
          number: { value: 200, density: { enable: true, value_area: 1200 } },
          color: { value: ['#B1A6A4', '#3B3F42', '#FF6F61', '#D1CFCF', '#F0EDE5', '#494A4C', '#C7B299', '#FDF5E6', '#B7BABF', '#DBE7E4'] },
          shape: { type: ['circle', 'edge', 'triangle', 'polygon', 'hexagon', 'pentagon', 'heart', 'diamond', 'ellipse'], stroke: { width: 0.6, color: '#FFFFFF' }, polygon: { nb_sides: 5 } },
          opacity: { value: 0.5, random: true, anim: { enable: true, speed: 0.4, opacity_min: 0.2, sync: false } },
          size: { value: 10, random: true, anim: { enable: true, speed: 4, size_min: 3, sync: false } },
          line_linked: { enable: false },
          move: { enable: true, speed: 2.5, direction: 'none', random: true, straight: false, out_mode: 'out', bounce: false }
        },
        interactivity: {
          detect_on: 'canvas',
          modes: {
            bubble: { distance: 300, size: 30, duration: 2, opacity: 0.8, speed: 2 },
            repulse: { distance: 400, duration: 0.8 },
            push: { particles_nb: 12 }
          }
        },
        retina_detect: true
      });
    }
  }, [isActive]);

  return (
    <>
      {isActive && <div id="particles-js"></div>}

      <div className={`signup-container ${isActive ? '' : 'inactive'}`}>
        <div className="signup-card">
          <div className="logo">
            <h1>kōdō.ai</h1>
          </div>
          <h1>Own Compliance. Scale Everywhere.</h1>
          <p>Fragrance brands use KODO.ai for fast, airtight compliance. Protect your business and eliminate hours of tedious work—instantly.</p>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              placeholder="Your Full Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              disabled={!isActive}
            />
            <input
              type="email"
              placeholder="Your Business Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={!isActive}
            />
            <textarea
              placeholder="What’s your biggest compliance challenge?"
              value={problem}
              onChange={(e) => setProblem(e.target.value)}
              disabled={!isActive}
            />
            <button type="submit" disabled={!isActive || loading}>
              {loading ? <span className="spinner"></span> : 'Try Our IFRA Compliance Checker'}
            </button>
            {error && <p className="error">{error}</p>}
            {success && <p className="success">{success}</p>}
          </form>

          {!isActive && <p className="inactive-message">Sign-up is currently inactive. Please check back later.</p>}
        </div>
      </div>
    </>
  );
};

export default DemoSignUp;
