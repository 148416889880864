import React from 'react';
import IngredientCard from './IngredientCard';  // Import the IngredientCard component
import '../styles/formulaDetails.css';
import { Link } from 'react-router-dom'; // Import Link for routing

const FormulaDetails = ({ perfumeData }) => {
  return (
    <div className="formula-details-container">
      <h2>Formula Breakdown for {perfumeData.name}</h2>

      {/* General perfume information */}
      <div className="perfume-info">
        <p><strong>Category:</strong> {perfumeData.category}</p>
        <p><strong>Allergens:</strong> {perfumeData.allergens.join(', ') || 'None'}</p>
      </div>

      {/* List of ingredients */}
      <div className="ingredient-list">
        {perfumeData.ingredients.map((ingredient, index) => (
          <IngredientCard key={index} ingredient={ingredient} />
        ))}
      </div>
    </div>
  );
};

export default FormulaDetails;
