import React from 'react';
import '../styles/document.css';

const mockDocuments = [
  { name: 'Floral Bliss IFRA Certificate', url: 'https://example.com/ifra' },
  { name: 'Woody Vibe MSDS', url: 'https://example.com/msds' },
];

const DocumentRepository = () => {
  return (
    <div className="document-repository">
      <h1>Document Repository</h1>
      <ul>
        {mockDocuments.map((doc, index) => (
          <li key={index}>
            <a href={doc.url} target="_blank" rel="noopener noreferrer">
              {doc.name}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DocumentRepository;
