// mockVendors.js

// Vendor data structured to cover a wide range of edge cases, including:
// 1. Vendors with missing, expired, or active certifications.
// 2. Different compliance statuses (e.g., Compliant, Pending, Non-Compliant).
// 3. Vendors from multiple regions with unique regulatory requirements.
// 4. Vendors with partial document submission and missing certifications.
// 5. Vendors with recent vs. overdue communication for more dynamic tracking.

const mockVendors = [
    // Edge Case: Fully Compliant Vendor with All Documents Up-to-Date
    {
      id: 1,
      name: 'Givaudan',
      complianceStatus: 'Compliant', // All certifications are up-to-date
      missingDocuments: [],
      documents: ['IFRA Certificate', 'ISO 14001 Certificate', 'Sustainability Report'],
      lastContact: '2024-09-28',
      communicationLog: [
        { date: '2024-06-15', message: 'Initial compliance audit completed successfully.' },
        { date: '2024-08-02', message: 'Requested updated IFRA certificate.' },
        { date: '2024-09-01', message: 'IFRA certificate received, all standards met.' },
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Active', expirationDate: '2025-09-01' },
        { type: 'ISO 14001', status: 'Active', expirationDate: '2026-02-20' },
        { type: 'Sustainability Compliance', status: 'Pending Renewal', expirationDate: '2024-12-15' }, // Pending Renewal for future notification
      ],
    },
  
    // Edge Case: Vendor with Expired Certification and Missing Documents
    {
      id: 2,
      name: 'BASF',
      complianceStatus: 'Pending', // Pending due to expired and missing documents
      missingDocuments: ['GMP Certification'], // GMP certification is required but missing
      documents: ['IFRA Certificate', 'Environmental Impact Report'],
      lastContact: '2024-09-20',
      communicationLog: [
        { date: '2024-07-15', message: 'Requested GMP certification renewal.' },
        { date: '2024-08-05', message: 'Reminder sent for GMP certification update.' },
        { date: '2024-09-10', message: 'Awaiting GMP certification document.' },
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Active', expirationDate: '2025-08-20' },
        { type: 'GMP Certification', status: 'Expired', expirationDate: '2024-07-01' }, // Expired certification
      ],
    },
  
    // Edge Case: Vendor with Missing Certifications and No Response
    {
      id: 3,
      name: 'Symrise',
      complianceStatus: 'Non-Compliant', // Non-compliant due to missing certifications
      missingDocuments: ['IFRA Certificate', 'Allergen Report'],
      documents: ['Environmental Policy Document'],
      lastContact: '2024-08-15', // No recent contact
      communicationLog: [
        { date: '2024-06-10', message: 'Requested IFRA certificate.' },
        { date: '2024-07-01', message: 'Follow-up on missing IFRA and Allergen reports.' },
        { date: '2024-08-01', message: 'No response received from vendor.' }, // No response for multiple requests
      ],
      certifications: [
        { type: 'ISO Certification', status: 'Expired', expirationDate: '2024-05-30' },
        { type: 'IFRA Certificate', status: 'Missing', expirationDate: null }, // Missing certificate
      ],
    },
  
    // Edge Case: Fully Compliant Vendor with Frequent Contact and No Outstanding Documents
    {
      id: 4,
      name: 'dsm-firmenich',
      complianceStatus: 'Compliant', // Compliant with regular updates
      missingDocuments: [],
      documents: ['IFRA Certificate', 'Sustainability Statement', 'ISO 9001'],
      lastContact: '2024-09-29',
      communicationLog: [
        { date: '2024-05-15', message: 'Routine compliance check completed.' },
        { date: '2024-07-10', message: 'IFRA and ISO 9001 certifications verified.' },
        { date: '2024-09-29', message: 'No outstanding documents required.' },
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Active', expirationDate: '2025-07-15' },
        { type: 'ISO 9001', status: 'Active', expirationDate: '2026-07-15' },
      ],
    },
  
    // Edge Case: Vendor with Multiple Missing and Expired Certifications
    {
      id: 5,
      name: 'IFF (International Flavors & Fragrances)',
      complianceStatus: 'Pending', // Compliance pending due to multiple missing documents
      missingDocuments: ['GMP Certification', 'Allergen Declaration'], // Critical missing documents
      documents: ['IFRA Certificate'],
      lastContact: '2024-08-25',
      communicationLog: [
        { date: '2024-06-20', message: 'Requested allergen report and GMP certification.' },
        { date: '2024-07-15', message: 'Sent reminder for GMP certification.' },
        { date: '2024-08-10', message: 'Partial documents received, awaiting allergen declaration.' },
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Active', expirationDate: '2025-06-20' },
        { type: 'GMP Certification', status: 'Pending Renewal', expirationDate: '2024-07-30' }, // Renewal needed
      ],
    },
  
    // Edge Case: Region-Specific Vendor with Unique Organic Certification
    {
      id: 6,
      name: 'Robertet – France',
      complianceStatus: 'Compliant',
      missingDocuments: [],
      documents: ['IFRA Certificate', 'Organic Certification'],
      lastContact: '2024-09-18',
      communicationLog: [
        { date: '2024-03-10', message: 'Initial compliance setup for organic certification.' },
        { date: '2024-06-15', message: 'Requested IFRA certification update.' },
        { date: '2024-09-01', message: 'Received all compliance documents.' },
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Active', expirationDate: '2025-03-10' },
        { type: 'Organic Certification', status: 'Active', expirationDate: '2025-09-18' }, // Organic compliance required
      ],
    },
  
    // Edge Case: Vendor with Active and Infrequent Compliance Interaction
    {
      id: 7,
      name: 'MANE – France',
      complianceStatus: 'Compliant',
      missingDocuments: [],
      documents: ['IFRA Certificate', 'GMP Certificate'],
      lastContact: '2024-09-12', // Contacted but with no issues
      communicationLog: [
        { date: '2024-07-10', message: 'Sent updated IFRA certificate.' },
        { date: '2024-08-15', message: 'Requested GMP compliance details.' },
        { date: '2024-09-12', message: 'Compliance check complete, all documents validated.' },
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Active', expirationDate: '2025-07-10' },
        { type: 'GMP Certification', status: 'Active', expirationDate: '2025-09-12' },
      ],
    },
  
    // Edge Case: Non-Compliant Vendor with Repeatedly Ignored Requests
    {
      id: 9,
      name: 'Ultra International – India',
      complianceStatus: 'Non-Compliant', // Flagged due to non-compliance
      missingDocuments: ['IFRA Certificate', 'Allergen Declaration'], // Repeatedly missing documents
      documents: [],
      lastContact: '2024-07-15',
      communicationLog: [
        { date: '2024-05-10', message: 'Requested compliance setup and allergen declaration.' },
        { date: '2024-06-15', message: 'Sent reminder for pending IFRA certificate.' },
        { date: '2024-07-15', message: 'No response from vendor; marked as non-compliant.' }, // Multiple missed follow-ups
      ],
      certifications: [
        { type: 'IFRA Certificate', status: 'Missing', expirationDate: null },
        { type: 'Allergen Declaration', status: 'Missing', expirationDate: null }, // Critical non-compliance issue
      ],
    },
  ];
  
  export default mockVendors;
  