import React from 'react';
import '../styles/workflow.css';

const mockWorkflows = [
  { workflow: 'Automated Vendor Reminders', status: 'Active' },
  { workflow: 'Document Review Workflow', status: 'Paused' },
];

const WorkflowAutomation = () => {
  return (
    <div className="workflow-automation">
      <h1>Workflow Automation</h1>
      <ul>
        {mockWorkflows.map((workflow, index) => (
          <li key={index}>
            <h3>{workflow.workflow}</h3>
            <p>Status: {workflow.status}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WorkflowAutomation;
