export const mockPerfumes = [
  {
    id: 1,
    name: 'Floral Bliss',
    description: 'A fresh floral fragrance with delicate top notes of rose and lily.',
    category: 'Floral',
    allergens: ['Linalool', 'Citronellol'],
    compliance: {
      ifraCertified: true,
      msdsAvailable: true,
      allergenFree: false,
    },
    ingredients: [
      {
        name: 'Rose Absolute',
        note: 'Middle Note',
        percentage: 15,
        supplier: 'Supplier A (Trusted Partner)',
        origin: 'Grasse, France',
        molecularWeight: '282.4 g/mol',
        casNumber: '8007-01-0',
        compliance: {
          ifraCertified: true,
          msdsAvailable: true,
          sustainable: true,
          veganFriendly: true,
          ecoCertified: false,
          reachCompliant: true,
        },
        complianceHistory: [
          { year: 2022, status: 'Compliant', region: 'EU' },
          { year: 2021, status: 'Compliant', region: 'US' },
          { year: 2020, status: 'Non-Compliant', region: 'IFRA' },
        ],
        alternatives: [
          { id: 1, name: 'Jasmine Absolute', complianceStatus: 'Compliant' },
          { id: 2, name: 'Lavender Extract', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 1,
            name: 'Supplier A (Trusted Partner)',
            documents: [
              { id: 1, type: 'IFRA Certificate', url: '/docs/rose-ifra.pdf' },
              { id: 2, type: 'MSDS', url: '/docs/rose-msds.pdf' },
            ],
          },
        ],
      },
      {
        name: 'Lily Extract',
        note: 'Middle Note',
        percentage: 10,
        supplier: 'Supplier B',
        origin: 'Netherlands',
        molecularWeight: '150.2 g/mol',
        casNumber: '84775-82-0',
        compliance: {
          ifraCertified: false,
          msdsAvailable: true,
          sustainable: false,
          veganFriendly: true,
          ecoCertified: true,
          reachCompliant: false,
        },
        complianceHistory: [
          { year: 2022, status: 'Non-Compliant', region: 'EU' },
          { year: 2021, status: 'Compliant', region: 'US' },
        ],
        alternatives: [
          { id: 3, name: 'Ylang Ylang Extract', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 2,
            name: 'Supplier B',
            documents: [
              { id: 3, type: 'MSDS', url: '/docs/lily-msds.pdf' },
            ],
          },
        ],
      },
      {
        name: 'Geranium Oil',
        note: 'Top Note',
        percentage: 8,
        supplier: 'Supplier C',
        origin: 'Egypt',
        molecularWeight: '152.3 g/mol',
        casNumber: '8000-46-2',
        compliance: {
          ifraCertified: true,
          msdsAvailable: true,
          sustainable: true,
          veganFriendly: true,
          ecoCertified: false,
          reachCompliant: true,
        },
        complianceHistory: [
          { year: 2022, status: 'Compliant', region: 'IFRA' },
          { year: 2021, status: 'Non-Compliant', region: 'US' },
        ],
        alternatives: [
          { id: 4, name: 'Lavender Oil', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 3,
            name: 'Supplier C',
            documents: [
              { id: 4, type: 'IFRA Certificate', url: '/docs/geranium-ifra.pdf' },
              { id: 5, type: 'MSDS', url: '/docs/geranium-msds.pdf' },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    name: 'Woody Vibe',
    description: 'A deep, woody aroma with sandalwood and patchouli base notes.',
    category: 'Woody',
    allergens: ['Eugenol'],
    compliance: {
      ifraCertified: true,
      msdsAvailable: true,
      allergenFree: false,
    },
    ingredients: [
      {
        name: 'Sandalwood Oil',
        note: 'Base Note',
        percentage: 20,
        supplier: 'Supplier C (Trusted Partner)',
        origin: 'Mysore, India',
        molecularWeight: '246.4 g/mol',
        casNumber: '8006-87-9',
        compliance: {
          ifraCertified: true,
          msdsAvailable: true,
          sustainable: true,
          veganFriendly: false,
          ecoCertified: true,
          reachCompliant: true,
        },
        complianceHistory: [
          { year: 2022, status: 'Compliant', region: 'EU' },
          { year: 2021, status: 'Compliant', region: 'US' },
        ],
        alternatives: [
          { id: 5, name: 'Cedarwood Oil', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 4,
            name: 'Supplier C',
            documents: [
              { id: 6, type: 'MSDS', url: '/docs/sandalwood-msds.pdf' },
            ],
          },
        ],
      },
      {
        name: 'Patchouli Oil',
        note: 'Base Note',
        percentage: 18,
        supplier: 'Supplier D',
        origin: 'Indonesia',
        molecularWeight: '222.4 g/mol',
        casNumber: '8014-09-3',
        compliance: {
          ifraCertified: true,
          msdsAvailable: false,
          sustainable: true,
          veganFriendly: true,
          ecoCertified: true,
          reachCompliant: true,
        },
        complianceHistory: [
          { year: 2022, status: 'Compliant', region: 'US' },
          { year: 2021, status: 'Non-Compliant', region: 'EU' },
        ],
        alternatives: [
          { id: 6, name: 'Vetiver Oil', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 5,
            name: 'Supplier D',
            documents: [
              { id: 7, type: 'IFRA Certificate', url: '/docs/patchouli-ifra.pdf' },
            ],
          },
        ],
      },
      {
        name: 'Cedarwood Oil',
        note: 'Top Note',
        percentage: 10,
        supplier: 'Supplier E',
        origin: 'USA',
        molecularWeight: '204.4 g/mol',
        casNumber: '8000-27-9',
        compliance: {
          ifraCertified: true,
          msdsAvailable: true,
          sustainable: false,
          veganFriendly: true,
          ecoCertified: true,
          reachCompliant: true,
        },
        complianceHistory: [
          { year: 2022, status: 'Compliant', region: 'IFRA' },
          { year: 2021, status: 'Compliant', region: 'EU' },
        ],
        alternatives: [
          { id: 7, name: 'Fir Balsam', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 6,
            name: 'Supplier E',
            documents: [
              { id: 8, type: 'MSDS', url: '/docs/cedarwood-msds.pdf' },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: 'Citrus Splash',
    description: 'A refreshing citrus scent with hints of orange and lemon zest.',
    category: 'Citrus',
    allergens: ['Limonene', 'Geraniol'],
    compliance: {
      ifraCertified: true,
      msdsAvailable: true,
      allergenFree: false,
    },
    ingredients: [
      {
        name: 'Orange Essential Oil',
        note: 'Top Note',
        percentage: 12,
        supplier: 'Supplier E',
        origin: 'Brazil',
        molecularWeight: '136.2 g/mol',
        casNumber: '8008-57-9',
        compliance: {
          ifraCertified: true,
          msdsAvailable: true,
          sustainable: false,
          veganFriendly: true,
          ecoCertified: false,
          reachCompliant: true,
        },
        complianceHistory: [
          { year: 2022, status: 'Compliant', region: 'IFRA' },
          { year: 2021, status: 'Non-Compliant', region: 'EU' },
        ],
        alternatives: [
          { id: 8, name: 'Grapefruit Oil', complianceStatus: 'Compliant' },
        ],
        suppliers: [
          {
            id: 7,
            name: 'Supplier E',
            documents: [
              { id: 9, type: 'IFRA Certificate', url: '/docs/orange-ifra.pdf' },
              { id: 10, type: 'MSDS', url: '/docs/orange-msds.pdf' },
            ],
          },
        ],
      },
      // More ingredients...
    ],
  },
  // Continue adding more perfumes and ingredients...
];
