import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import PerfumeDashboard from './components/PerfumeDashboard';
import VendorManagement from './components/VendorManagement';
import ProductComplianceTracker from './components/ProductComplianceTracker';
import ComplianceAutomationHub from './components/ComplianceAutomationHub';
import RegulatoryIntelligence from './components/RegulatoryIntelligence';
import DocumentRepository from './components/DocumentRepository';
import WorkflowAutomation from './components/WorkflowAutomation';
import TextPhotoProcessor from './components/TextPhotoProcessor';
import Sidebar from './components/Sidebar';
import DemoSignUp from './components/DemoSignUp';
import './styles/app.css';

const App = () => {
  const [isSignedUp, setIsSignedUp] = useState(false);

  const handleSignUp = () => {
    console.log("User has signed up.");
    setIsSignedUp(true);
  };

  const useCurrentPath = () => {
    const location = useLocation();
    console.log("Current path:", location.pathname);
    return location.pathname;
  };

  console.log("App component rendered. isSignedUp:", isSignedUp);

  return (
    <Router>
      <div className="app-container">
        {!isSignedUp ? (
          <DemoSignUp onSignUp={handleSignUp} />
        ) : (
          <MainContent useCurrentPath={useCurrentPath} />
        )}
      </div>
    </Router>
  );
};

const MainContent = ({ useCurrentPath }) => {
  const currentPath = useCurrentPath();

  console.log("MainContent rendered. Current path:", currentPath);

  return (
    <>
      {currentPath !== '/' && <Sidebar />}
      <div className="main-content">
        <Routes>
          <Route path="/vendor-management" element={<VendorManagement />} />
          <Route path="/vendor-management/:vendorId" element={<VendorManagement />} />
          <Route path="/compliance-tracker" element={<ProductComplianceTracker />} />
          <Route path="/compliance-automation" element={<ComplianceAutomationHub />} />
          <Route path="/regulatory-intelligence" element={<RegulatoryIntelligence />} />
          <Route path="/document-repository" element={<DocumentRepository />} />
          <Route path="/workflow-automation" element={<WorkflowAutomation />} />
          <Route path="/" element={<TextPhotoProcessor />} />
          <Route path="/perfume-dashboard" element={<PerfumeDashboard />} />
        </Routes>
      </div>
    </>
  );
};

export default App;
