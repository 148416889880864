import React from 'react';

const IngredientCard = ({ ingredient }) => {
  return (
    <div className="ingredient-card">
      {/* Ingredient Details */}
      <h3>{ingredient.name}</h3>
      <p>Molecular Weight: {ingredient.molecularWeight} g/mol</p>
      
      {/* Ingredient Compliance History and Trends */}
      <div className="compliance-history">
        <h4>Compliance History:</h4>
        <ul>
          {ingredient.complianceHistory.map((record) => (
            <li key={record.year}>
              {record.year}: {record.status} ({record.region})
            </li>
          ))}
        </ul>
      </div>

      {/* Compliance Badges */}
      <div className="ingredient-compliance-badges">
        {ingredient.compliance.ifraCertified && <span className="badge badge-ifra">IFRA Certified</span>}
        {ingredient.compliance.msdsAvailable && <span className="badge badge-msds">MSDS Available</span>}
        {ingredient.compliance.sustainable && <span className="badge badge-sustainable">Sustainable</span>}
        {ingredient.compliance.vegan && <span className="badge badge-vegan">Vegan</span>}
        {ingredient.compliance.ecoFriendly && <span className="badge badge-eco">Eco-Friendly</span>}
        {ingredient.compliance.reachCompliant && <span className="badge badge-reach">REACH Compliant</span>}
      </div>

      {/* Alternative Ingredient Suggestions */}
      {ingredient.alternatives && ingredient.alternatives.length > 0 && (
        <div className="alternative-ingredients">
          <h4>Alternative Ingredients:</h4>
          <ul>
            {ingredient.alternatives.map((alt) => (
              <li key={alt.id}>
                {alt.name} (Compliance: {alt.complianceStatus})
              </li>
            ))}
          </ul>
        </div>
      )}

      {/* Supplier Information */}
      <h4>Suppliers:</h4>
      <ul>
        {ingredient.suppliers.map((supplier) => (
          <li key={supplier.id}>
            <p><strong>{supplier.name}</strong></p>
            <p>Documents:</p>
            <ul>
              {supplier.documents.map((doc) => (
                <li key={doc.id}>
                  <a href={doc.url} target="_blank" rel="noopener noreferrer">{doc.type} Document</a>
                </li>
              ))}
            </ul>
          </li>
        ))}
      </ul>

      {/* Proprietary Ingredient Security */}
      <p className="security-note">* Proprietary ingredient data is protected with advanced encryption.</p>
    </div>
  );
};

export default IngredientCard;
