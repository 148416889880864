import React from 'react';
import '../styles/automation.css';

const mockAutomationTasks = [
  { task: 'LLM Document Parsing for Floral Bliss', status: 'Completed' },
  { task: 'Automated Compliance Check for Woody Vibe', status: 'In Progress' },
];

const ComplianceAutomationHub = () => {
  return (
    <div className="compliance-automation">
      <h1>Compliance Automation Hub</h1>
      <ul>
        {mockAutomationTasks.map((task, index) => (
          <li key={index}>
            <h3>{task.task}</h3>
            <p>Status: {task.status}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComplianceAutomationHub;
