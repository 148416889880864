// AddVendorForm.js

import { useState } from 'react';
import '../styles/addvendor.css'; // Import styles for vendor management layout and visuals

const AddVendorForm = ({ onAddVendor, onCancel }) => {
  const [name, setName] = useState('');
  const [complianceStatus, setComplianceStatus] = useState('');
  const [lastContact, setLastContact] = useState('');
  const [documents, setDocuments] = useState(''); // Input for documents as comma-separated string
  const [missingDocuments, setMissingDocuments] = useState(''); // Input for missing documents as comma-separated string
  const [communicationLog, setCommunicationLog] = useState(''); // Input for communication log as JSON-like string

  const handleSubmit = e => {
    e.preventDefault();

    // Convert comma-separated inputs to arrays
    const documentsArray = documents ? documents.split(',').map(doc => doc.trim()) : [];
    const missingDocumentsArray = missingDocuments ? missingDocuments.split(',').map(doc => doc.trim()) : [];

    // Create an initial communication log object (splitting by newlines or other separators can be an option)
    const communicationLogArray = communicationLog ? communicationLog.split('\n').map(entry => {
      const [date, message] = entry.split(':'); // Assume "date: message" format for entries
      return { date: date.trim(), message: message.trim() };
    }) : [];

    if (name && complianceStatus) {
      onAddVendor({
        name,
        complianceStatus,
        lastContact,
        documents: documentsArray,
        missingDocuments: missingDocumentsArray,
        communicationLog: communicationLogArray
      });
      // Clear form after submission
      setName('');
      setComplianceStatus('');
      setLastContact('');
      setDocuments('');
      setMissingDocuments('');
      setCommunicationLog('');
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        <h2>Add New Vendor</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Vendor Name:
            <input
              type="text"
              value={name}
              onChange={e => setName(e.target.value)}
              required
            />
          </label>
          <label>
            Compliance Status:
            <input
              type="text"
              value={complianceStatus}
              onChange={e => setComplianceStatus(e.target.value)}
              required
            />
          </label>
          <label>
            Last Contact:
            <input
              type="date"
              value={lastContact}
              onChange={e => setLastContact(e.target.value)}
            />
          </label>
          <label>
            Documents (comma-separated):
            <input
              type="text"
              value={documents}
              onChange={e => setDocuments(e.target.value)}
              placeholder="e.g., IFRA Certificate, ISO 14001"
            />
          </label>
          <label>
            Missing Documents (comma-separated):
            <input
              type="text"
              value={missingDocuments}
              onChange={e => setMissingDocuments(e.target.value)}
              placeholder="e.g., Sustainability Report"
            />
          </label>
          <button type="submit">Add Vendor</button>
          <button type="button" onClick={onCancel}>Cancel</button>
        </form>
      </div>
    </div>
  );
};

export default AddVendorForm;
