import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      {/* Text-based logo */}
      <div className="logo">
        <h1>kōdō.ai</h1>  {/* Project name styled as a logo */}
      </div>

      <ul>
        <li><Link to="/perfume-dashboard">Perfume Dashboard</Link></li>
        <li><Link to="/vendor-management">Vendor Management</Link></li>
        <li><Link to="/compliance-tracker">Product Compliance Tracker</Link></li>
        <li><Link to="/compliance-automation">Compliance Automation Hub</Link></li>
        <li><Link to="/regulatory-intelligence">Regulatory Intelligence</Link></li>
        <li><Link to="/document-repository">Document Repository</Link></li>
        <li><Link to="/workflow-automation">Workflow Automation</Link></li>
      </ul>
    </div>
  );
};

export default Sidebar;
